export const languages = [
  {
    key: 'python',
    name: "Python Django",
    color: '#FFD43B'
  },
  {
    key: 'ruby',
    name: "Ruby on Rails",
    color: '#A91401'
  },
  {
    key: 'react',
    name: "React.js",
    color: "#61dafb"
  }
]